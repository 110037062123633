import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '~store/index';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useTreeStore } from '~store/tree/treeModule';
// eslint-disable-next-line import/no-cycle
import { usePatientStore } from '~store/patient/patientModule';
import { Org } from './types';

// const api = new Api();

export const useOrgsStore = defineStore('orgs', {
  state: () => ({
    fullOrgList: [] as any | Org[],
    currentOrg: null as number | null,
  }),
  getters: {

  },
  actions: {
    async getFullOrgList() {
      const params = {
        Name_event: 'GET:orgs_list_full',
        Name_module: 'WEB_managers',
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.fullOrgList = data;
        }
      }
    },
    async setInitialOrg(): Promise<void> {
      await this.getFullOrgList();
      const userId = useAuthStore().currentUser.id_login;
      const orgInStorage = localStorage.getItem(`medSafeCompany-${userId}`);
      let savedOrg: number | null = null;

      try {
        if (orgInStorage) savedOrg = JSON.parse(orgInStorage) as number;
      } catch (error) {
        useMainStore().showMessage('Не удается загрузить организацию');
      }

      if (!savedOrg) {
        const currentOrg = this.fullOrgList?.length === 1
          ? this.fullOrgList[0].id_org
          : null;
        if (currentOrg) {
          this.setCurrentOrg(currentOrg);
        }
        return;
      }
      this.setCurrentOrg(savedOrg);
    },
    setCurrentOrg(currentOrg: number): void {
      const userId = useAuthStore().currentUser.id_login;
      if (currentOrg) {
        this.currentOrg = currentOrg;
        localStorage.setItem(`medSafeCompany-${userId}`, JSON.stringify(currentOrg));
      }
    },
  },
});
