// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '~store/index';
// eslint-disable-next-line import/no-cycle
import { useOrgsStore } from '~store/orgs/orgsModule';
// eslint-disable-next-line import/no-cycle
import { useTreeStore } from '~store/tree/treeModule';
// eslint-disable-next-line import/no-cycle
import { useManagerStore } from '../manager/managerModule';
import {
  PaymentType,
  MOType,
  HazardType,
  MP_Hazard,
  ContractsMatrix,
  Catalog,
} from './types';

const MODULE_PATIENTS = 'module_patients';

export const usePatientStore = defineStore('patient', {
  state: () => ({
    paymentTypes: null as any | PaymentType[],
    MOTypes: null as any | MOType[],
    hazardTypes: null as any | HazardType[],
    patientReferral: null as any,
    patientHazards: null as any,
    hazardsList: [] as any | MP_Hazard[],
    contractsMatrix: [] as any | ContractsMatrix[],
    matrixDict: [] as any | [],
    filters: {
      id_contract: [] as number[],
      id_pay_type: [] as number[],
      id_type_mo: [] as number[],
      id_legal_instruments: [] as number[],
      id_branch: [] as number[],
    },
    fewContractsFlag: false,
    hazardServices: [] as any[],
  }),
  getters: {},
  actions: {
    async getPaymentTypes(): Promise<void> {
      const params = {
        Name_event: 'GET:spr_payment_type',
        Name_module: MODULE_PATIENTS,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.paymentTypes = data;
        }
      }
    },

    async getMOTypes(id_org: number): Promise<void> {
      const params = {
        Name_event: 'GET:type_MO_spr',
        Name_module: MODULE_PATIENTS,
        id_org,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.MOTypes = data;
        }
      }
    },

    async getHazardTypes(): Promise<void> {
      const params = {
        Name_event: 'GET:hazards_types_spr',
        Name_module: MODULE_PATIENTS,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.hazardTypes = data;
        }
      }
    },

    async getHazardsList(legal_instrument: 0): Promise<void> {
      const params = {
        Name_event: 'GET:hazards',
        Name_module: MODULE_PATIENTS,
        legal_instrument,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.hazardsList = data;
        }
      }
    },

    async getPatientHazards(id_patient: number): Promise<void> {
      const params = {
        Name_event: 'GET:patient_hazards',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.patientHazards = data;
        }
      }
    },

    async getHazardServices(id_patient: number): Promise<void> {
      const params = {
        Name_event: 'GET:hazard_services',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.hazardServices = data;
        }
      }
    },

    async getContractsMatrix(id_tree: string): Promise<void> {
      const params = {
        Name_event: 'GET:contracts_matrix',
        Name_module: MODULE_PATIENTS,
        id_org: useOrgsStore().currentOrg ?? 0,
        id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.contractsMatrix = data;
        }
      }
    },

    async getMatrixDict(): Promise<void> {
      const params = {
        Name_event: 'GET:matrix_dict',
        Name_module: MODULE_PATIENTS,
        id_org: useOrgsStore().currentOrg ?? 0,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const parsed = data.map((i: any) => {
            const tmp = JSON.parse(i.catalog);
            // eslint-disable-next-line no-param-reassign
            i.catalog = tmp;
            return i;
          });
          this.matrixDict = parsed;
        }
      }
    },

    async getDirection(id_patient: number): Promise<void> {
      const params = {
        Name_event: 'GET:web_napravl_data',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const tmp = data.map((i) => {
            if (i.catalog) {
              const t = JSON.parse(i.catalog);
              // eslint-disable-next-line no-param-reassign
              i.catalog = t;
            }
            if (i.data_type === 'json') {
              const text = JSON.parse(i.text);
              // eslint-disable-next-line no-param-reassign
              i.text = text;
            }
            return i;
          });
          this.patientReferral = tmp;
        }
      }
    },

    async findTempPatient(id_patient: number): Promise<any> {
      const params = {
        Name_event: 'GET:find_pat',
        Name_module: MODULE_PATIENTS,
        search_str: JSON.stringify({
          id_patient,
        }),
        nomerok_only: 0,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const [tmpPat] = data;
          return tmpPat;
        }
      }
      return null;
    },

    async previewDocument(type: number, id_patient: number): Promise<string> {
      const params = {
        Name_module: 'medAdmin',
        Name_event: 'GET:doc_preview',
        str_param: JSON.stringify({
          doc_type: 53,
          id_patient,
          id_spec: useMainStore().constantParams.id_login,
          napravl_opo_param: type,
          file_name: 'Направление',
          tag: 'for_web',
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const [tmpPat] = data;
          return tmpPat.html;
        }
      }
      return '';
    },

    async createPatient(context: any): Promise<any> {
      const params = {
        Name_event: 'POST:patient',
        Name_module: MODULE_PATIENTS,
        str_params: JSON.stringify({
          id_contract: this.filters.id_contract.join(),
          type_mo_arr: this.filters.id_type_mo.join(),
          id_branch_arr: this.filters.id_branch.join(),
          pay_type_arr: this.filters.id_pay_type.join(),
          legal_instrument_arr: this.filters.id_legal_instruments.join(),
          id_tree: context.id_tree,
          id_tree_people: context.id_tree_people,
          lastname: context.lastname,
          firstname: context.firstname,
          middlename: context.middlename,
          sex: context.sex,
          birthdate: context.birthdate,
          gen_napravl: 0,
          temp_patient: 1,
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          useTreeStore().getActualPatients(false, true);
          const [tmpPat]: any = data;
          return tmpPat;
        }
      } return null;
    },

    async editPatient(context: any): Promise<any> {
      console.log(context);
      const params = {
        Name_event: 'POST:patient_edit',
        Name_module: MODULE_PATIENTS,
        id_patient: context.id_patient,
        str_params: JSON.stringify({
          id_contract: this.filters.id_contract.join(),
          type_mo_arr: this.filters.id_type_mo.join(),
          id_branch_arr: this.filters.id_branch.join(),
          pay_type_arr: this.filters.id_pay_type.join(),
          legal_instrument_arr: this.filters.id_legal_instruments.join(),
          id_tree: context.id_tree,
          id_tree_people: context.id_tree_people,
          id_patient: context.id_patient,
          id_tree_people_resp: context.id_tree_people_resp ?? 0,
          gen_napravl: 0,
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async editStaff(context: any): Promise<any> {
      const params = {
        Name_event: 'POST:patient_edit',
        Name_module: MODULE_PATIENTS,
        id_tree_people: context.id_tree_people,
        str_params: JSON.stringify({
          id_tree: context.id_tree,
          id_tree_people: context.id_tree_people,
          lastname: context.lastname,
          firstname: context.firstname,
          middlename: context.middlename,
          sex: context.sex,
          birthdate: context.birthdate,
          gen_napravl: 0,
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async appendPatient(id_patient: number): Promise<any> {
      const params = {
        Name_event: 'POST:append_patient',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async deletePatient(id_patient: number): Promise<any> {
      const params = {
        Name_event: 'POST:patient_del',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        useTreeStore().getActualPatients(false, true);
        return true;
      } return null;
    },

    async cancelPatient(id_patient: number): Promise<any> {
      const params = {
        Name_event: 'POST:pat_status_otmen',
        Name_module: 'SmartPRO',
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        useTreeStore().getActualPatients(false, true);
        return true;
      } return null;
    },

    async bindTreeToContract(
      id_tree: string,
      id_contract: number,
      for_children = 0,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:contract_NN_tree',
        Name_module: 'SmartPRO',
        id_tree,
        id_contract,
        for_children,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          // await this.getMatrixDict();
          await this.getContractsMatrix(id_tree);
        }
      } return null;
    },

    async removeTreeContractBinding(
      id_tree: string,
      id_contract: number,
      for_children = 0,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:del_contract_NN_tree',
        Name_module: 'SmartPRO',
        id_tree,
        id_contract,
        for_children,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          // await this.getMatrixDict();
          await this.getContractsMatrix(id_tree);
        }
      } return null;
    },

    async addTmpHazard(
      id_patient: number,
      id_sevice: number,
      param: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:temp_hazard',
        Name_module: MODULE_PATIENTS,
        id_patient,
        id_sevice,
        param,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        await this.getPatientHazards(id_patient);
      } return null;
    },

    async removeTmpHazard(
      id_patient: number,
      id_sevice: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:del_hazard',
        Name_module: MODULE_PATIENTS,
        id_patient,
        id_sevice,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        await this.getPatientHazards(id_patient);
      } return null;
    },

    async removeAllTmpHazard(
      id_patient: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:hazard_clear_temp',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        await this.getPatientHazards(id_patient);
      } return null;
    },

    async addTmpService(
      id_patient: number,
      id_sevice: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:temp_service',
        Name_module: MODULE_PATIENTS,
        id_patient,
        id_sevice,
        param: 1,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async appendTmpServices(
      id_patient: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:append_services',
        Name_module: MODULE_PATIENTS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async removeTmpService(
      id_patient: number,
      id_sevice: number,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:del_service',
        Name_module: MODULE_PATIENTS,
        id_patient,
        id_sevice,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        return true;
      } return null;
    },

    async appendTmpHazards(
      id_patient: number,
      id_tree = 0 as unknown as string,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:hazard_check_n_append',
        Name_module: MODULE_PATIENTS,
        id_patient,
        id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        await this.getPatientHazards(id_patient);
        if (id_tree) {
          await useManagerStore().getHazardNode(useTreeStore().treeNode);
          const changedNodeIndex = useTreeStore()
            .selectedNodesMap[useTreeStore().selectedNodesMap.length - 1].nodeEntries
            .findIndex((i: any) => i.id_tree === useTreeStore().treeNode);
          if (changedNodeIndex > -1) {
            useTreeStore()
              .selectedNodesMap[useTreeStore().selectedNodesMap.length - 1]
              .nodeEntries[changedNodeIndex].cnt_hazard = useManagerStore().hazardNode.length;
          }
        }
      } return null;
    },

    async contractEditRequest(
      context: any,
    ): Promise<any> {
      const params = {
        Name_event: 'POST:smart_request',
        Name_module: MODULE_PATIENTS,
        id_contract: context.id_contract,
        request_data: JSON.stringify(context.request_data),
        text: context.text,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
          return true;
        }
      } return null;
    },
  },
});
